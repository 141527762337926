var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "episerver-accordion",
      class: { "o-bg": _vm.backgroundColor, color: _vm.textColor },
      style: _vm.style,
    },
    _vm._l(_vm.model.content.content, function (item, index) {
      return _c("div", { key: index, staticClass: "accordion-item" }, [
        _c(
          "div",
          {
            staticClass: "accordion-header",
            on: {
              click: function ($event) {
                return _vm.toggleItem(index)
              },
            },
          },
          [
            _c("span", [
              _c(
                "div",
                { staticClass: "accordion-header" },
                [_c("XhtmlField", { attrs: { items: item.heading } })],
                1
              ),
            ]),
            _vm._v(" "),
            _c("svg", { staticClass: "toggle-sign" }, [
              _c("use", {
                attrs: {
                  "xlink:href": _vm.activeIndex === index ? "#minus" : "#plus",
                },
              }),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.activeIndex === index,
                expression: "activeIndex === index",
              },
            ],
            staticClass: "accordion-content",
          },
          [_c("XhtmlField", { attrs: { items: item.content } })],
          1
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }