var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "epi-link",
    {
      staticClass: "o-link",
      class: [
        {
          "o-link--fancy": _vm.isFancy,
          "o-link--external": _vm.isExternal,
          "o-link--no-style": _vm.noStyle,
        },
      ],
      attrs: {
        isClickable: _vm.isClickable,
        href: _vm.href ? _vm.href : "#",
        target: _vm.isExternal ? "_blank" : _vm.target,
        download: _vm.download,
      },
    },
    [
      _vm.icon
        ? _c("Icon", { staticClass: "o-link__icon", attrs: { icon: _vm.icon } })
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }