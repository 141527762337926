<!--By default all links will open in the current tab unless target is set. -->

<template>
    <epi-link :isClickable="isClickable" :href="href ? href : '#'" :target="isExternal ? '_blank' : target" class="o-link" :class="[{'o-link--fancy' : isFancy, 'o-link--external': isExternal, 'o-link--no-style': noStyle}]" :download="download">
        <Icon v-if="icon" :icon="icon" class="o-link__icon"/>
        <slot/>
    </epi-link>
</template>

<script>
import Icon from '@/Scripts/components/atoms/Icon.vue';
import EpiLink from '@/Scripts/components/atoms/EpiLink.vue';

export default {
    components: {
        Icon,
        EpiLink
    },
    props: {
        isClickable: {
            type: Boolean,
            default: true // eslint-disable-line
        },
        onClick: Function,
        icon: {
            type: String,
        },
        download: {
            type: String
        },
        href: {
            // so the console doesn't spew out errors
            validator: prop => typeof prop === 'string' || prop === null,
            required: true
        },
        isFancy: {
            type: Boolean,
            default: false // eslint-disable-line
        },
        isExternal: {
            type: Boolean,
            default: false // eslint-disable-line
        },
        noStyle: {
            type: Boolean
        },
        target: {
            validator: function (value) {
                // The value must match one of these strings
                return ['_blank', '_parent', '_self', '_top'].indexOf(value) !== -1;
            },
            default: '_self' // eslint-disable-line
        }
    }
};
</script>
